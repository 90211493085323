import { getEuroFormat, getEuroFormatFromEuro, getPercentFormat } from '../Money'
import { getDateFormatted } from '../Date'

// eslint-disable-next-line no-unused-vars
export default ({bulletin, name, date, settings, constants}) => {
    // let NBJP = 18;
    // let NBJF = 20;
    let row;
    // let nbh = 151.67;

    let body = [
        [{ text: 'RUBRIQUES', alignment: 'center', margin: [0, 0, 0, 0], style: 'tableheader' }, { text: 'BASE', alignment: 'center', margin: [0, 0, 0, 0], style: 'tableheader' }, { text: ' MONTANT SALARIAL ', alignment: 'center', colSpan: 2, margin: [0, 0, 0, 0], style: 'tableheader'}, '', { text: 'MONTANT PATRONAL', alignment: 'center', colSpan: 2, margin: [0, 0, 0, 0], style: 'tableheader'}, ''],
    ]

    let index = body.length // consider that the length is the current new index
    const pushAndGetIndex = (content, currentIndex) => {
        body.push(content)
        return ++currentIndex
    }
    const showCheck = (row) => {
        // the selected line made the final choice of showing or not the row
        return !row.selectedLine || (row.tauxS === 0 && row.tauxP === 0 && row.unselectable)
    }

    const base_salary_index = index
    if (settings.companySettings.convention === constants.COLLECTIVE_CONVENTION.PORTAGE) {
        index = pushAndGetIndex([{ text: 'SALAIRE DE BASE', style: 'tableSubheader', color: 'white' }, { text: getEuroFormatFromEuro(bulletin['baseHoursWorked'], false), alignment: 'right', style: 'tableSubheader', color: 'white' }, { text: '', alignment: 'center', style: 'tableSubheader', color: 'white' }, { text: getEuroFormat(bulletin['SCOMP'], false), alignment: 'right', style: 'tableSubheader', color: 'white' }, '', ''], index)
    }

    index = pushAndGetIndex([{ text: settings.companySettings.convention === constants.COLLECTIVE_CONVENTION.PORTAGE ? 'Complément de salaire' : 'SALAIRE DE BASE', style: 'tableSubheader', color: 'white' },   { text: settings.companySettings.convention === constants.COLLECTIVE_CONVENTION.PORTAGE ? '' : getEuroFormatFromEuro(bulletin['baseHoursWorked'], false), alignment: 'right', style: 'tableSubheader', color: 'white' }, { text: settings.companySettings.convention === constants.COLLECTIVE_CONVENTION.PORTAGE ? '' : getEuroFormat(bulletin['globalSalaryRate'], false), alignment: 'center', style: 'tableSubheader', color: 'white' },   { text: getEuroFormat(bulletin['SBC'], false), alignment: 'right', style: 'tableSubheader', color: 'white' }, '', ''], index)
    const global_rows_index = index
    bulletin['rows'].globalRows.forEach((globalRow) => {
        if (globalRow.tauxS !== 0 && globalRow.tauxP !== 0) {
            row = globalRow
            index = pushAndGetIndex([{ text: row.label, style: 'tableData' }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, { text: getPercentFormat(row.tauxS), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantS, false), alignment: 'right', style: 'tableData' }, '', ''], index)
        }
    })
    // console.log((bulletin['SB']))
    // console.log(getEuroFormat(bulletin['SB']))
    const brut_salary_index = index
    index = pushAndGetIndex([{ text: 'SALAIRE BRUT', style: 'tableSubheader', color: 'white' }, { text: '', alignment: 'right', style: 'tableSubheader', color: 'white' }, { text: '', alignment: 'center', style: 'tableSubheader', color: 'white' }, { text: getEuroFormat(bulletin['SB'], false), alignment: 'right', style: 'tableSubheader', color: 'white' }, '', ''], index)
    const separation_brut_salary_index = index
    index = pushAndGetIndex([{ text: '', colSpan: 6, margin: [0, 0, 0, 0]}, '', '', '', '', ''], index)

    // SANTE
    // in all cases we show the default "header", there is always Secu.Soc-Mal.Mater.inval.Déc
    const health_index = index
    index = pushAndGetIndex([{ text: 'SANTE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''], index)
    bulletin['rows'].healthRows.forEach((healthRow) => {
        // healthRow.selectedLine && (row.tauxS !== 0 && row.tauxP !== 0 && row.unselectable)
        if (healthRow.selectedLine && (healthRow.unselectable ? (healthRow.tauxS !== 0 && healthRow.tauxP !== 0) : true)) {
            row = healthRow
            index = pushAndGetIndex([{ text: row.label, style: 'tableData', margin: [16, 0, 0, 0] }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, { text: row.tauxS ? getPercentFormat(row.tauxS) : '', alignment: 'center', style: 'tableData' }, { text: row.montantS ? getEuroFormat(row.montantS, false) : '', alignment: 'right', style: 'tableData' }, { text: getPercentFormat(row.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }], index)
        }
    })

    // AT-MP
    const allATMP_RowsUnSelected = bulletin['rows'].ATMP_Rows.every((ATMP_row) => showCheck(ATMP_row))
    const ATMP_index = allATMP_RowsUnSelected ? -1 : index
    if (!allATMP_RowsUnSelected) {
        index = pushAndGetIndex([{ text: 'AT-MP', style: 'tableSubheader', colSpan: 6 }, '', '', '', '', ''], index)
        bulletin['rows'].ATMP_Rows.forEach((ATMP_row) => {
            if (ATMP_row.selectedLine) {
                row = ATMP_row
                index = pushAndGetIndex([{ text: row.label, style: 'tableData', margin: [16, 0, 0, 0] }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, '', '', { text: getPercentFormat(row.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }], index)
            }
        })
    }

    // RETRAITE
    const allRetirementRowsUnSelected = bulletin['rows'].retirementRows.every((retirementRow) => !retirementRow.selectedLine)
    const retirement_index = allRetirementRowsUnSelected ? -1 : index
    if (!allRetirementRowsUnSelected) {
        index = pushAndGetIndex([{ text: 'RETRAITE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''], index)
        bulletin['rows'].retirementRows.forEach((retirementRow) => {
            if (retirementRow.selectedLine) {
                row = retirementRow
                index = pushAndGetIndex([{ text: row.label, style: 'tableData', margin: [16, 0, 0, 0] }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, { text: getPercentFormat(row.tauxS), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantS, false), alignment: 'right', style: 'tableData' }, { text: getPercentFormat(row.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }], index)
            }
        })
    }

    // FAMILLE-SECURITE SOCIALE
    const allSocialSecurityRowsUnSelected = bulletin['rows'].socialSecurityRows.every((socialSecurityRow) => !socialSecurityRow.selectedLine)
    const social_security_family_index = allSocialSecurityRowsUnSelected ? -1 : index
    if (!allSocialSecurityRowsUnSelected) {
        index = pushAndGetIndex([{ text: 'FAMILLE-SECURITE SOCIALE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''], index)
        bulletin['rows'].socialSecurityRows.forEach((socialSecurityRow) => {
            if (socialSecurityRow.selectedLine) {
                row = socialSecurityRow
                index = pushAndGetIndex([{ text: row.label, style: 'tableData', margin: [16, 0, 0, 0] }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, '', '', { text: getPercentFormat(row.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }], index)
            }
        })
    }

    // ASSURANCE CHOMAGE
    const allUnemploymentInsuranceRowsUnSelected = bulletin['rows'].unemploymentInsuranceRows.every((unemploymentInsuranceRow) => !unemploymentInsuranceRow.selectedLine)
    const unemployment_insurance_index = allUnemploymentInsuranceRowsUnSelected ? -1 : index
    if (!allUnemploymentInsuranceRowsUnSelected) {
        index = pushAndGetIndex([{ text: 'ASSURANCE CHOMAGE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''], index)
        bulletin['rows'].unemploymentInsuranceRows.forEach((unemploymentInsuranceRow) => {
            if (unemploymentInsuranceRow.selectedLine) {
                row = unemploymentInsuranceRow
                index = pushAndGetIndex([{ text: row.label, style: 'tableData', margin: [16, 0, 0, 0] }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, { text: row.tauxS ? getPercentFormat(row.tauxS) : '', alignment: 'center', style: 'tableData' }, { text: row.montantS ? getEuroFormat(row.montantS, false) : '', alignment: 'right', style: 'tableData' }, { text: getPercentFormat(row.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }], index)
            }
        })
    }

    // AUTRES CONTRIB. DUES PAR EMPL.
    const allOtherContributionRowsUnSelected = bulletin['rows'].otherContributionRows.every((otherContributionRow) => !otherContributionRow.selectedLine)
    const others_employer_contributions_index = allOtherContributionRowsUnSelected ? -1 : index
    if (!allOtherContributionRowsUnSelected) {
        index = pushAndGetIndex([{ text: 'AUTRES CONTRIB. DUES PAR EMPL.', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''], index)
        bulletin['rows'].otherContributionRows.forEach((otherContributionRow) => {
            if (otherContributionRow.selectedLine) {
                index = pushAndGetIndex([{ text: otherContributionRow.label, style: 'tableData', margin: [16, 0, 0, 0] }, { text: getEuroFormat(otherContributionRow.base, false), alignment: 'right', style: 'tableData' }, '', '', { text: getPercentFormat(otherContributionRow.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(otherContributionRow.montantP, false), alignment: 'right', style: 'tableData' }], index)
            }
        });
    }

    const csg_index = index
    bulletin['rows'].CSG_rows.forEach((CSG_row) => {
        if (CSG_row.selectedLine) {
            row = CSG_row
            index = pushAndGetIndex([{ text: row.label, style: 'tableSubheader' }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, { text: getPercentFormat(row.tauxS), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantS, false), alignment: 'right', style: 'tableData' }, '', ''], index)
        }
    })
    const csg_crds_index = index
    bulletin['rows'].CSG_CRDS_rows.forEach((CSG_CRDS_row) => {
        if (CSG_CRDS_row.selectedLine) {
            row = CSG_CRDS_row
            index = pushAndGetIndex([{ text: row.label, style: 'tableSubheader' }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, { text: getPercentFormat(row.tauxS), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantS, false), alignment: 'right', style: 'tableData' }, '', ''], index)
        }
    })
    const ADESATT_index = settings.companySettings.convention === constants.COLLECTIVE_CONVENTION.SYNTEC ? index : -1
    bulletin['rows'].ADESATT_rows.forEach((ADESATT_row) => {
        if (ADESATT_row.selectedLine && settings.companySettings.convention === constants.COLLECTIVE_CONVENTION.SYNTEC) {
            row = ADESATT_row
            index = pushAndGetIndex([{ text: row.label, style: 'tableSubheader' }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, '', '', { text: getPercentFormat(row.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }], index)
        }
    })
    const statuary_fee_index = index
    bulletin['rows'].statuaryFeeRows.forEach((statuaryFeeRow) => {
        if (statuaryFeeRow.selectedLine) {
            row = statuaryFeeRow
            index = pushAndGetIndex([{ text: row.label, style: 'tableSubheader' }, { text: getEuroFormat(row.base, false), alignment: 'right', style: 'tableData' }, '', '', { text: getPercentFormat(row.tauxP), alignment: 'center', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }], index)
        }
    })

    const separation_start_index = index
    index = pushAndGetIndex([{ text: '', colSpan: 6, margin: [0, 0, 0, 0]}, '', '', '', '', ''], index)
    // const separation_content_index = index
    index = pushAndGetIndex([{ text: 'TOTAL DES RETENUES', style: 'tableSubheader', colSpan: 3 }, '', '', { text: getEuroFormat(bulletin['TotRS'], false), alignment: 'right', style: 'tableSubheader' }, { text: '', alignment: 'center', style: 'tableData' }, { text: getEuroFormat(bulletin['TotRP'], false), alignment: 'right', style: 'tableSubheader' }], index)
    const separation_end_index = index
    index = pushAndGetIndex([{ text: '', colSpan: 6, margin: [0, 0, 0, 0]}, '', '', '', '', ''], index)
    bulletin['rows'].othersRows.forEach((othersRow) => {
        row = othersRow
        index = pushAndGetIndex([{ text: row.label, style: 'tableSubheader' }, row.base !== null ? { text: row.base, alignment: 'right', style: 'tableData' } : '', row.tauxS ? { text: getPercentFormat(row.tauxS), alignment: 'right', style: 'tableData' } : '', row.montantS !== null ? { text: getEuroFormat(row.montantS, false), alignment: 'right', style: 'tableData' } : '', row.tauxP ? { text: getPercentFormat(row.tauxP), alignment: 'right', style: 'tableData' } : '', row.montantP !== null ? { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' } : ''], index)
    })
    // row = bulletin['rows'][25]
    // body.push([{ text: row.label, style: 'tableSubheader' }, { text: row.base, alignment: 'right', style: 'tableData' }, { text: getEuroFormat(row.tauxS, false), alignment: 'right', style: 'tableData' }, { text: getEuroFormat(row.montantS, false), alignment: 'right', style: 'tableData' }, { text: getEuroFormat(row.tauxP, false), alignment: 'right', style: 'tableData' }, { text: getEuroFormat(row.montantP, false), alignment: 'right', style: 'tableData' }])

    // const major_movement_index = index
    index = pushAndGetIndex([{ text: 'GRAND DEPLACEMENT (justifié par la mission)', style: 'tableSubheader' }, '', '', '', '', ''], index)

    // REPAS HOTEL
    bulletin['rows'].feesRows.forEach((statuaryFeeRow) => {
        row = statuaryFeeRow
        index = pushAndGetIndex([{ text: row.label, style: 'tableData', margin: [16, 0, 0, 0] }, { text: row.base, alignment: 'right', style: 'tableData' }, { text: getEuroFormat(row.tauxS, false), alignment: 'right', style: 'tableData' }, { text: getEuroFormat(row.montantS, false), alignment: 'right', style: 'tableData' }, '', ''], index)
    })

    const split_column_separation = index
    index = pushAndGetIndex([{ text: '', margin: [0, 0, 0, 0], colSpan: 6, border: [false, false, false, false]}, '', '', '', '', ''], index)

    index = pushAndGetIndex([
        {text: 'NET IMPOSABLE', style: 'tableData', margin: [0, 3, 0, 3]},
        {text: getEuroFormat(bulletin['SNETI'], false), margin: [0, 3, 0, 3], style: 'tableData', alignment: 'right'},
        {text: '', border: [false, false, false, false]},
        {text: 'Total cot.salariales', margin: [0, 3, 0, 3], style: 'tableData', colSpan: 2},
        '',
        {text: getEuroFormat(bulletin['TotRS'], false), margin: [0, 3, 0, 3], style: 'tableData', alignment: 'right'}
    ], index)

    index = pushAndGetIndex([
        {text: 'Salaire Net avant PAS', style: 'tableData', margin: [0, 3, 0, 3]},
        {text: getEuroFormat(bulletin['NET_SALARY_BEFORE_PAS'], false), margin: [0, 3, 0, 3], style: 'tableData', alignment: 'right'},
        {text: '', border: [false, false, false, false]},
        {text: 'Total cot.patronales', margin: [0, 3, 0, 3], style: 'tableData', colSpan: 2},
        '',
        {text: getEuroFormat(bulletin['EMPLOYER_CONTRIBUTION_TOTAL'], false), margin: [0, 3, 0, 3], style: 'tableData', alignment: 'right'}
    ], index)

    index = pushAndGetIndex([
        {text: 'Salaire Net avant PAS après TR', style: 'tableData', margin: [0, 3, 0, 3]},
        {text: getEuroFormat(bulletin['NET_SALARY_BEFORE_PAS_AFTER_RESTAURANTS_TICKETS'], false), margin: [0, 3, 0, 3], style: 'tableData', alignment: 'right'},
        {text: '', border: [false, false, false, false]},    {text: 'Total des retenues', margin: [0, 3, 0, 3], style: 'tableData', colSpan: 2},
        '',
        {text: getEuroFormat(bulletin['TOTAL_EMPLOYER_SALARY_CONTRIBUTIONS'], false), margin: [0, 3, 0, 3], style: 'tableData', alignment: 'right'}
        ], index)

    const salary_slip_global_cost_index = index
    index = pushAndGetIndex([
        {text: `PAS (taux à ${getPercentFormat(bulletin['PAST'])})`, style: 'tableData'},
        {text: getEuroFormat(bulletin['PAS'], false), style: 'tableData', alignment: 'right'},
        {text: '', border: [false, false, false, false]},
        {text: 'Coût global du bulletin de salaire', style: 'tableData', colSpan: 2},
        '',
        {text: getEuroFormat(bulletin['SALARY_BULLETIN_GLOBAL_COST'], false), style: 'tableData', alignment: 'right'}
    ], index)

    index = pushAndGetIndex([
        {text: 'Frais professionnels justifiés (FPJ)', style: 'tableData'},
        {text: getEuroFormat(bulletin['FPJ'], false), style: 'tableData', alignment: 'right'},
        {text: '', border: [false, false, false, false]},
        {text: 'Ratio (Net à payer après impôts + FPJ + TR)/cout global', style: 'tableData', colSpan: 2},
        '',
        {text: getPercentFormat(bulletin['RATIO_NET_TO_PAY_ON_GLOBAL_COST']), style: 'tableData', alignment: 'right'}
    ], index)

    const net_to_pay_index = index

    index = pushAndGetIndex([
        {text: 'Net à payer après impôts + FPJ', style: 'tableSubheader', color: 'white'},
        {text: getEuroFormat(bulletin['NET_TO_PAY'], false), style: 'tableSubheader', alignment: 'right', color: 'white'},
        {text: '', border: [false, false, false, false]},
        {text: 'Réserve financière', style: 'tableData', colSpan: 2},
        '',
        {text: getEuroFormat(bulletin['RESERVEFINANCIERE'], false), style: 'tableData', alignment: 'right'}
    ], index)

    index = pushAndGetIndex([
        {text: ' +TR  (en valeur faciale) si vous les prenez', style: 'tableData'},
        {text: getEuroFormat(bulletin['TR'], false), style: 'tableData', alignment: 'right'},
        {text: '', border: [false, false, false, false]},
        {text: `Cagnotte générée par mois`, style: 'tableData', colSpan: 2},
        '',
        {text: getEuroFormat(bulletin['CAGNOTTE'], false), style: 'tableData', alignment: 'right'}
    ], index)

    // reinit watermark after work if needed-- before pageMargins
    // watermark: { text: 'SPECIMEN', color: 'blue', opacity: 0.3, bold: true },

    var docDefinition = {
        info: {
            title: 'Simulation de bulletin de salaire',
            author: 'EPPORTAGE',
            subject: 'Simulation de bulletin de salaire',
        },
        watermark: { text: 'SPECIMEN', color: 'blue', opacity: 0.3, bold: true },
        pageMargins: [20, 8, 20, 0],
        content: [
            {
                table: {
                    widths: [120, '*', 120],

                    body: [
                        [
                            { text: '', border: [false, false, false, false], decoration: 'underline', alignment: 'center' },
                            { text: 'SIMULATION\nBULLETIN DE SALAIRE', alignment: 'center', bold: true, color: '#1F4E79', margin: [0, 1, 0, 1] },
                        ],
                    ]
                },
                margin: [0, 0, 0, 0]
            },
            {
                table: {
                    widths: ['*', '*'],

                    body: [
                        [
                            { text: 'NOM PRENOM: ' + name, alignment: 'left', bold: true, border: [false, false, false, false], fontSize: 10 },
                            { text: 'Date de simulation: ' + getDateFormatted(date), border: [false, false, false, false], alignment: 'left', bold: true, fontSize: 10 },
                        ],
                    ]
                },
                margin: [0, 0, 0, 1]
            },
            {
                table: {
                    widths: [179, 51, 45, 90, 80, 56],
                    alignments: ['center','center', 'center', 'center', 'center', 'center'],
                    body: body
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        if (rowIndex === 0) {
                            return '#D9D9D9'
                        }
                        if (((rowIndex >= base_salary_index && rowIndex < global_rows_index)  || rowIndex === brut_salary_index) && columnIndex < 4) {
                            return '#00B0F0'
                        }
                        if ((rowIndex === net_to_pay_index) && columnIndex < 2) {
                            return '#00B0F0'
                        }
                        if (rowIndex === separation_start_index || rowIndex === separation_end_index) {
                            return '#BFBFBF'
                        }
                        if (rowIndex === salary_slip_global_cost_index && columnIndex > 2) {
                            return '#BFBFBF'
                        }
                        return '#FFFFFF';
                    },
                    hLineColor: function (rowIndex, node, columnIndex) {
                        if (
                        [
                            0, 1, health_index, health_index + 1, ATMP_index, ATMP_index + 1, retirement_index, retirement_index + 1, social_security_family_index, social_security_family_index + 1,
                            unemployment_insurance_index, unemployment_insurance_index + 1, others_employer_contributions_index, others_employer_contributions_index + 1, csg_crds_index, csg_crds_index + 1,
                            csg_index, csg_index + 1, ADESATT_index, ADESATT_index + 1, statuary_fee_index, statuary_fee_index + 1, separation_start_index, separation_start_index + 1, separation_end_index,
                            separation_end_index + 1, split_column_separation, split_column_separation + 1
                        ].indexOf(rowIndex) !== -1) {
                            return 'black'
                        }
                        if ([separation_brut_salary_index].indexOf(rowIndex) !== -1 && columnIndex < 1) {
                            return 'black'
                        }
                        if (rowIndex > split_column_separation + 1) {
                            return 'black'
                        }
                        return '#D9D9D9';
                    },
                    vLineColor: function (columnIndex, node, rowIndex) {
                        if ([0, 6, 1, 2, 4].indexOf(columnIndex) !== -1) {
                            return 'black'
                        }
                        // [3].indexOf(columnIndex) !== -1 && [0, 1, 2, 3, 4, 5, 6, 7].indexOf(rowIndex) !== -1
                        if (rowIndex >= base_salary_index && rowIndex <= brut_salary_index) {
                            return 'black'
                        }
                        if (rowIndex > separation_start_index && rowIndex < body.length && columnIndex === 3 ) {
                            return 'black'
                        }
                        if (rowIndex > separation_end_index && rowIndex < body.length && columnIndex === 5 ) {
                            return 'black'
                        }
                        return '#D9D9D9';
                    },
                },
                margin: [0, 0, 0, 32]
            }
        ],
        styles: {
            tableheader: {
                fontSize: 8
            },
            tableSubheader: {
                fontSize: 7,
                bold: true
            },
            tableData: {
                fontSize: 7,
            },
        },
        defaultStyle: {
            // fontSize: 8
            // alignment: 'justify'
        }
    }
    // {
    //     pageSize: {
    //         width: 595.28,
    //         height: 1100
    //     },
    //     pageMargins: [0, 10, 0, 10],
    //     // watermark: { text: 'Page 1', color: 'blue', opacity: 0.3, bold: true, italics: false },
    //     // { colSpan: 2, rowSpan: 2, text: 'Both:\nrowSpan and colSpan\ncan be defined at the same time' }
    //     content: [
    //         {
    //             table: {
    //                 widths: [120, '*', 120],
    //
    //                 body: [
    //                     [
    //                         { text: '', border: [false, false, false, false], decoration: 'underline', alignment: 'center' },
    //                         { text: 'SIMULATION\nBULLETIN DE SALAIRE', alignment: 'center', bold: true, color: '#1F4E79', margin: [0, 8, 0, 8] },
    //                         { text: 'sur 18 jours', border: [false, false, false, false], decoration: 'underline', alignment: 'right', bold: true, color: 'red', margin: [0, 12, 0, 0] },
    //                     ],
    //                 ]
    //             },
    //             margin: [0, 0, 0, 8]
    //         },
    //         {
    //             table: {
    //                 widths: ['*', '*'],
    //
    //                 body: [
    //                     [
    //                         { text: 'NOM PRENOM: BESSY MAXENCE', alignment: 'left', bold: true, border: [false, false, false, false] },
    //                         { text: 'Date de simulation 10/02/2022', border: [false, false, false, false], alignment: 'left', bold: true },
    //                     ],
    //                 ]
    //             },
    //             margin: [0, 0, 0, 8]
    //         },
    //         {
    //             table: {
    //                 widths: [189, 61, 55, 100, 65, 71],
    //                 alignments: ['center','center', 'center', 'center', 'center', 'center'],
    //                 body: [
    //                     [{ text: 'RUBRIQUES', alignment: 'center', margin: [0, 6, 0, 0], style: 'tableheader' }, { text: 'BASE', alignment: 'center', margin: [0, 6, 0, 0], style: 'tableheader' }, { text: 'TAUX\nSALARIAL', alignment: 'center', style: 'tableheader' }, { text: 'MONTANT SALARIAL', alignment: 'center', margin: [0, 6, 0, 0], style: 'tableheader' }, { text: 'MONTANT PATRONAL', alignment: 'center', colSpan: 2, margin: [0, 6, 0, 0], style: 'tableheader'}, ''],
    //                     [{ text: 'SALAIRE DE BASE', style: 'tableSubheader', color: 'white' },   { text: '151,67', alignment: 'right', style: 'tableSubheader', color: 'white' },   { text: '6,3889', alignment: 'center', style: 'tableSubheader', color: 'white' },   { text: '969,00', alignment: 'right', style: 'tableSubheader', color: 'white' }, '', ''],
    //                     [{ text: 'Prime d\'apporteur d\'affaire', style: 'tableData' },          { text: '969,00', alignment: 'right', style: 'tableData' },                        { text: '5%', alignment: 'center', style: 'tableData' },                            { text: '48,45', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: 'Prime d\'objectif', style: 'tableData' },                      { text: '', alignment: 'right', style: 'tableData' },                              { text: '', alignment: 'center', style: 'tableData' },                              { text: '', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: '', style: 'tableData'},                                        { text: '-', alignment: 'right', style: 'tableData' },                             { text: '', alignment: 'center', style: 'tableData' },                              { text: '-', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: 'Indemnité Congés Payés', style: 'tableData' },                 { text: '1017,45', alignment: 'right', style: 'tableData' },                       { text: '10%', alignment: 'center', style: 'tableData' },                           { text: '101,75', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: 'Prime conventionnelle de vacances', style: 'tableData' },      { text: '101,75', alignment: 'right', style: 'tableData' },                        { text: '10%', alignment: 'center', style: 'tableData' },                           { text: '10,17', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: 'SALAIRE BRUT', style: 'tableSubheader', color: 'white' },      { text: '', alignment: 'right', style: 'tableSubheader', color: 'white' },         { text: '', alignment: 'center', style: 'tableSubheader', color: 'white' },         { text: '1129,37', alignment: 'right', style: 'tableSubheader', color: 'white' }, '', ''],
    //                     [{ text: '', colSpan: 6, margin: [0, 10, 0, 0]}, '', '', '', '', ''],
    //                     [{ text: 'SANTE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''],
    //                     [{ text: 'Sécu.Soc-Mal.Mater.Inval.Déc.', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '13%', alignment: 'center', style: 'tableData' }, { text: '146,82', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Complémentaire Incap.Inval.Déc', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '1,5%', alignment: 'center', style: 'tableData' }, { text: '51,42', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Complémentaire Incap.Inval.Déc', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '2 298,63', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '2,06%', alignment: 'center', style: 'tableData' }, { text: '47,35', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Complémentaire Incap.Inval.Déc', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '-', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '2,06%', alignment: 'center', style: 'tableData' }, { text: '-', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Mutuelle Obligatoire', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '1,355%', alignment: 'center', style: 'tableData' },                            { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '1,355%', alignment: 'center', style: 'tableData' }, { text: '46,45', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'AT-MP', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''],
    //                     [{ text: 'Acc. du trav. - Mal. prof.', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'RETRAITE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''],
    //                     [{ text: 'Sécu.Soc Plafonnée', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '1,355%', alignment: 'center', style: 'tableData' },                            { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '1,355%', alignment: 'center', style: 'tableData' }, { text: '46,45', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Sécu.Soc Déplafonnée', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '1,355%', alignment: 'center', style: 'tableData' },                            { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '1,355%', alignment: 'center', style: 'tableData' }, { text: '46,45', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Complémentaire Tranche A', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '1,355%', alignment: 'center', style: 'tableData' },                            { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '1,355%', alignment: 'center', style: 'tableData' }, { text: '46,45', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Complémentaire Tranche B', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '1,355%', alignment: 'center', style: 'tableData' },                            { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '1,355%', alignment: 'center', style: 'tableData' }, { text: '46,45', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'FAMILLE-SECURITE SOCIALE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''],
    //                     [{ text: 'AF', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'ASSURANCE CHOMAGE', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''],
    //                     [{ text: 'Chômage', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'AGS', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'APEC', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '1,355%', alignment: 'center', style: 'tableData' },                            { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '1,355%', alignment: 'center', style: 'tableData' }, { text: '46,45', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'APEC', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '3 428,00', alignment: 'right', style: 'tableData' },                        { text: '1,355%', alignment: 'center', style: 'tableData' },                            { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '1,355%', alignment: 'center', style: 'tableData' }, { text: '46,45', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'AUTRES CONTRIB. DUES PAR EMPL.', style: 'tableSubheader', colSpan: 6}, '', '', '', '', ''],
    //                     [{ text: 'Autres contrib. dues par empl.\n(Formation 1,6%,\nTaxe app 0,5%,\nContribution dialogue social 0,016%,\nContribution solidarité autonomie 0,30%,\nContribution dialogue apprentissage 0,18%,\nTransport 0,5%)', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'Autres contrib. dues par empl.\n(Forfait social prévoyance)', style: 'tableData', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'CSG non imposable/déductible à l\'IR', style: 'tableSubheader', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '6,8%', alignment: 'center', style: 'tableData' },                            { text: '78,89', alignment: 'right', style: 'tableData' }, { text: '', alignment: 'center', style: 'tableData' }, { text: '', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'ADESATT', style: 'tableSubheader', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: 'COTISATIONS STATUTAIRES OU CC', style: 'tableSubheader', margin: [16, 0, 0, 0] },          { text: '1 129,37', alignment: 'right', style: 'tableData' },                        { text: '', alignment: 'center', style: 'tableData' },                            { text: '', alignment: 'right', style: 'tableData' }, { text: '0,9%', alignment: 'center', style: 'tableData' }, { text: '10,16', alignment: 'right', style: 'tableData' }],
    //                     [{ text: '', colSpan: 6, margin: [0, 10, 0, 0]}, '', '', '', '', ''],
    //                     [{ text: 'TOTAL DES RETENUES', style: 'tableSubheader', colSpan: 3 }, '', '', { text: '282,28', alignment: 'right', style: 'tableSubheader' }, { text: '', alignment: 'center', style: 'tableData' }, { text: '526,32', alignment: 'right', style: 'tableSubheader' }],
    //                     [{ text: 'Cotis. Retraite/Prév./F.santé', style: 'tableData', colSpan: 3 }, '', '', { text: '46,45', alignment: 'right', style: 'tableData' }, { text: '', colSpan: 2, rowSpan: 8 }, ''],
    //                     [{ text: 'NET IMPOSABLE', style: 'tableSubheader', colSpan: 3 }, '', '', { text: '893,54', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: 'Prélèvement à la source', style: 'tableSubheader', color: 'white' }, { text: '', colSpan: 2 }, '', { text: '-', alignment: 'right', style: 'tableData', color: 'white' }, '', ''],
    //                     [{ text: 'CSG/CRDS imposable à l\'IR', style: 'tableSubheader' }, '', '', '', '', ''],
    //                     [{ text: 'CSG/CRDS imposable à l\'IR', style: 'tableData', margin: [16, 0, 0, 0] }, { text: '1 160,12', alignment: 'right', style: 'tableData' }, { text: '2,9%', alignment: 'center', style: 'tableData' }, { text: '33,64', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{text: '', margin: [0, 8, 0, 0]}, '', '', '', '', ''],
    //                     [{ text: 'FRAIS PROFESSIONNELS sur justificatifs', style: 'tableSubheader' }, '', '', {text: '-', alignment: 'right', style: 'tableData'}, '', ''],
    //                     [{text: '', margin: [0, 8, 0, 0]}, '', '', '', '', ''],
    //                     [{ text: 'TICKETS RESTAURANT', style: 'tableSubheader' }, '', '', '', '', ''],
    //                     [{text: '', margin: [0, 8, 0, 0]}, '', '', '', {text: '', rowSpan: 6, colSpan: 2}, ''],
    //                     [{ text: 'GRAND DEPLACEMENT\n(justifié par la mission)', style: 'tableSubheader' }, '', '', '', '', ''],
    //                     [{ text: 'Forfait Midi', style: 'tableData', margin: [16, 0, 0, 0] }, { text: '1 160,12', alignment: 'right', style: 'tableData' }, { text: '2,9%', alignment: 'center', style: 'tableData' }, { text: '33,64', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: 'Forfait Soir', style: 'tableData', margin: [16, 0, 0, 0] }, { text: '1 160,12', alignment: 'right', style: 'tableData' }, { text: '2,9%', alignment: 'center', style: 'tableData' }, { text: '33,64', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{ text: 'Forfait Hotel', style: 'tableData', margin: [16, 0, 0, 0] }, { text: '1 160,12', alignment: 'right', style: 'tableData' }, { text: '2,9%', alignment: 'center', style: 'tableData' }, { text: '33,64', alignment: 'right', style: 'tableData' }, '', ''],
    //                     [{text: '', margin: [0, 8, 0, 0]}, '', '', '', '', ''],
    //                     [{text: '', rowSpan: 9, colSpan: 3, border: [false, false, false, false]}, '', '', {text: '', colSpan: 3, border: [false, false, false, false], margin: [0, 8, 0, 0]}, '', ''],
    //                     ['', '', '', {text: 'Total cot.patronales', style: 'tableData', colSpan: 2}, '', {text: '526,32', style: 'tableData', alignment: 'right'}],
    //                     ['', '', '', {text: 'Total des retenues	', style: 'tableData', colSpan: 2}, '', {text: '526,32', style: 'tableData', alignment: 'right'}],
    //                     ['', '', '', {text: 'Coût global période', style: 'tableData', colSpan: 2}, '', {text: '526,32', style: 'tableData', alignment: 'right'}],
    //                     ['', '', '', {text: 'Salaire Net avant PAS	', style: 'tableSubheader', colSpan: 2}, '', {text: '526,32', style: 'tableData', alignment: 'right'}],
    //                     ['', '', '', {text: 'PAS (taux à 0%)', style: 'tableSubheader', colSpan: 2}, '', {text: '526,32', style: 'tableData', alignment: 'right'}],
    //                     ['', '', '', {text: 'FRAIS PROFESSIONNELS', style: 'tableSubheader', colSpan: 2}, '', {text: '526,32', style: 'tableData', alignment: 'right'}],
    //                     ['', '', '', {text: 'Net à payer après impôts', style: 'tableSubheader', colSpan: 2, margin: [0, 6, 0, 6], color: 'white'}, '', {text: '526,32', style: 'tableData', alignment: 'right', color: 'white', margin: [0, 6, 0, 6]}],
    //                     ['', '', '', {text: '+TICKET RESTAURANT\n(en valeur faciale) si vous les prenez', style: 'tableSubheader', colSpan: 2}, '', {text: '526,32', style: 'tableData', alignment: 'right', margin: [0, 6, 0, 6]}],
    //                 ]
    //             },
    //             layout: {
    //                 fillColor: function (rowIndex, node, columnIndex) {
    //                     if (rowIndex === 0) {
    //                         return '#D9D9D9'
    //                     }
    //                     if ((rowIndex === 1 || rowIndex === 7 || rowIndex === 39) && columnIndex < 4) {
    //                         return '#00B0F0'
    //                     }
    //                     if (rowIndex === 35) {
    //                         return '#BFBFBF'
    //                     }
    //                     if ((rowIndex === 59) && columnIndex > 2) {
    //                         return '#00B0F0'
    //                     }
    //                     if ((rowIndex === 55) && columnIndex > 2) {
    //                         return '#D9D9D9'
    //                     }
    //                     return '#FFFFFF';
    //                 },
    //                 hLineColor: function (rowIndex, node, columnIndex) {
    //                     if (rowIndex < 53) {
    //                         if ([0, 1, 9, 10, 15, 16, 17, 18, 22, 23, 24, 25, 29, 30, 32, 33, 34, 35, 36, 37, 38, 52].indexOf(rowIndex) !== -1) {
    //                             return 'black'
    //                         }
    //                         if ([8].indexOf(rowIndex) !== -1 && columnIndex < 1) {
    //                             return 'black'
    //                         }
    //                     } else {
    //                         return 'black'
    //                     }
    //                     return '#D9D9D9';
    //                 },
    //                 vLineColor: function (columnIndex, node, rowIndex) {
    //                     if (rowIndex < 52) {
    //                         if ([0, 6, 1, 2, 4].indexOf(columnIndex) !== -1) {
    //                             return 'black'
    //                         }
    //                         if ([3].indexOf(columnIndex) !== -1 && [0, 1, 2, 3, 4, 5, 6, 7].indexOf(rowIndex) !== -1 ) {
    //                             return 'black'
    //                         }
    //                         if (rowIndex > 35 && rowIndex < 52 && columnIndex === 3 ) {
    //                             return 'black'
    //                         }
    //                     } else {
    //                         return '#D9D9D9'
    //                     }
    //                     return '#D9D9D9';
    //                 },
    //                 // eslint-disable-next-line no-unused-vars
    //                 hLineStyle: function (rowIndex, node, columnIndex) {
    //                     if (rowIndex < 53) {
    //                         return null;
    //                     }
    //                     return { dash: { length: 1 } };
    //                 },
    //                 // eslint-disable-next-line no-unused-vars
    //                 vLineStyle: function (columnIndex, node) {
    //                     return null;
    //                 },
    //             },
    //             margin: [0, 0, 0, 32]
    //         }
    //     ],
    //     styles: {
    //         tableheader: {
    //             fontSize: 9
    //         },
    //         tableSubheader: {
    //             fontSize: 8,
    //             bold: true
    //         },
    //         tableData: {
    //             fontSize: 8,
    //         },
    //     },
    //     defaultStyle: {
    //         // fontSize: 8
    //         // alignment: 'justify'
    //     }
    // }
    return docDefinition
}
